/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React from "react";
import styled from "styled-components";

const LandingBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: main;
`;

export const Landing = () => (
  <LandingBase>
    <h1>Welcome!!</h1>
    <h3>Please test the following:</h3>
    <ul>
      <li>Edit User Profile</li>
      <li>Fully Working Result Page</li>
      <li>Each Move in Result Page is Clickable and Renders the State of Game</li>
    </ul>
  </LandingBase>
);
