/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
`;

export const Game = ({ readOnly = false }) => {
  const { id, step } = useParams();
  const initialState = {
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  };

  let [state, setState] = useState(initialState);
  let [readOnlyState, setReadOnlyState] = useState(initialState);

  let [target, setTarget] = useState({
    cards: [],
    src: "",
    dst: "",
  });

  const dummy = { suit: "dummy", value: "dummy", up: true };
  const cleanTarget = { cards: [], src: "", dst: "" };
  const insertDummyCardIfEmpty = (pileData) =>
    pileData.length === 0 ? [dummy] : pileData;

  const applyDummyCard = (data) => ({
    stack1: insertDummyCardIfEmpty(data.stack1),
    stack2: insertDummyCardIfEmpty(data.stack2),
    stack3: insertDummyCardIfEmpty(data.stack3),
    stack4: insertDummyCardIfEmpty(data.stack4),
    pile1: insertDummyCardIfEmpty(data.pile1),
    pile2: insertDummyCardIfEmpty(data.pile2),
    pile3: insertDummyCardIfEmpty(data.pile3),
    pile4: insertDummyCardIfEmpty(data.pile4),
    pile5: insertDummyCardIfEmpty(data.pile5),
    pile6: insertDummyCardIfEmpty(data.pile6),
    pile7: insertDummyCardIfEmpty(data.pile7),
    draw: insertDummyCardIfEmpty(data.draw),
    discard: insertDummyCardIfEmpty(data.discard),
  });

  // if params.step is defined, fetch the game state at that step
  if (step) {
    useEffect(() => {
      const getReadOnlyGameState = async () => {
        try {
          // Fetch game state
          const response = await fetch(`/v1/game/${id}/${step}`);
          const data = await response.json();
          setReadOnlyState(applyDummyCard(data));
        } catch (error) {
          console.error("Error fetching game state:", error.message);
        }
      };
      getReadOnlyGameState();
    }, [id, step]);
  } else {
    // if params.step is not defined, fetch the current game state
    useEffect(() => {
      const getGameState = async () => {
        const response = await fetch(`/v1/game/${id}`);
        const data = await response.json();
        setState(applyDummyCard(data.state));
      };
      getGameState();
    }, [id]);
  }


  // at the first click, select the card and all the cards below it
  const firstClick = async (ev, suit, value, pile, up) => {
    ev.preventDefault();
    if (suit === "dummy" || !up) {
      return;
    }

    // find the position of the selected card in the pile
    let cardIndex = state[pile].findIndex((card) => {
      return card.suit === suit && card.value === value;
    });

    // select the card and all the cards below it
    let target_cards = state[pile].slice(cardIndex);

    setTarget({
      cards: target_cards,
      src: pile,
      dst: "",
    });
  };

  // at second click, move selected cards to destination pile
  const secondClick = async (ev, pile) => {
    ev.preventDefault();

    setTarget({
      cards: target.cards,
      src: target.src,
      dst: pile,
    });

    // Send move request to server
    const response = await fetch(`/v1/game/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cards: target.cards,
        src: target.src,
        dst: pile,
      }),
    });

    if (!response.ok) {
      // console.log("ERROR invalid move: ", response);
      setTarget(cleanTarget);
      return;
    }

    // valid move, update the game state
    const data = await response.json();
    setState(applyDummyCard(data.state));

    setTarget(cleanTarget);
  };

  // handle click on a card
  const onClick = async (ev) => {
    ev.preventDefault();
    // if game is read-only, do nothing
    if (readOnly) {
      return;
    }

    let clickedCard = ev.target;
    let [suit, value, pile, up] = clickedCard.id.split(":");
    up = up === "true"; // convert string to boolean

    // if click on invalid place, reset the target
    if (!clickedCard.id) {
      setTarget(cleanTarget);
      return;
    }

    // if click draw pile, send draw request to server
    if (pile === "draw") {
      const response = await fetch(`/v1/game/${id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cards: { suit: suit, value: value, up: up },
          src: pile,
          dst: "discard",
        }),
      });

      if (!response.ok) {
        // console.log("ERROR invalid draw: ", response);
        setTarget(cleanTarget);
        return;
      }

      // valid draw, update the game state
      const data = await response.json();
      setState(applyDummyCard(data.state));
      return;
    }

    // if there's no card selected, treat as first click
    target.cards.length === 0
      ? firstClick(ev, suit, value, pile, up)
      : secondClick(ev, pile);
  };

  const gameState = readOnly ? readOnlyState : state;

  return (
    <GameBase>
      <CardRow>
        <Pile
          cards={gameState.stack1}
          spacing={0}
          pile={"stack1"}
          onClick={onClick}
        />
        <Pile
          cards={gameState.stack2}
          spacing={0}
          pile={"stack2"}
          onClick={onClick}
        />
        <Pile
          cards={gameState.stack3}
          spacing={0}
          pile={"stack3"}
          onClick={onClick}
        />
        <Pile
          cards={gameState.stack4}
          spacing={0}
          pile={"stack4"}
          onClick={onClick}
        />
        <CardRowGap />
        <Pile
          cards={gameState.draw}
          spacing={0}
          pile={"draw"}
          onClick={onClick}
        />
        <Pile
          cards={gameState.discard}
          spacing={0}
          pile={"discard"}
          onClick={onClick}
        />
      </CardRow>
      <CardRow>
        <Pile cards={gameState.pile1} pile={"pile1"} onClick={onClick} />
        <Pile cards={gameState.pile2} pile={"pile2"} onClick={onClick} />
        <Pile cards={gameState.pile3} pile={"pile3"} onClick={onClick} />
        <Pile cards={gameState.pile4} pile={"pile4"} onClick={onClick} />
        <Pile cards={gameState.pile5} pile={"pile5"} onClick={onClick} />
        <Pile cards={gameState.pile6} pile={"pile6"} onClick={onClick} />
        <Pile cards={gameState.pile7} pile={"pile7"} onClick={onClick} />
      </CardRow>
    </GameBase>
  );
};

Game.propTypes = {};
