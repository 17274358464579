import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const EditProfileContainer = styled.div`
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const EditProfileTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

export const EditProfile = () => {
  const { username } = useParams();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    city: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log("formData: ", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Make a fetch request to update the user's profile
    try {
      const response = await fetch(`/v1/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        // Redirect back to the user's profile page after successful update
        window.location.href = `/profile/${username}`;
      } else {
        console.error("Error updating profile");
      }
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  return (
    <EditProfileContainer>
      <EditProfileTitle>Edit Profile</EditProfileTitle>
      <form onSubmit={handleSubmit}>
        <div>
          <FormLabel htmlFor="first_name">First Name:</FormLabel>
          <FormInput
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <FormLabel htmlFor="last_name">Last Name:</FormLabel>
          <FormInput
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <FormLabel htmlFor="city">City:</FormLabel>
          <FormInput
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <FormButton type="submit">Save Changes</FormButton>
        </div>
      </form>
    </EditProfileContainer>
  );
};
